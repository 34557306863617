import React, { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { useSnapshot } from "valtio";
import { toast } from "react-toastify";

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Table from "@mui/material/Table";
import TableRow from "@mui/material/TableRow";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import Typography from "@mui/material/Typography";
import { styled } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { TextField } from "@mui/material";
import md5 from "md5";
import CloseIcon from "@mui/icons-material/Close";
import { ConfigContext } from "context/config";
import AppContext from "context/AppContext";
import state from "state";
import ColumnCount from "../ColumnCount";

import { toastSuccess, toastError } from "common/Toast";
import ModelLog from "common/ModelLogo";
import UserMenu from "common/UserMenu";
import OverForte from "common/OverForte";

import ValidationForm from "./ValidationForm";
import PrevNext from "./PrevNext";
import { ComfortBtn } from "./ComfortDesign";
import Darken from "../Darken";
import Description from "./Description";
import { CalcCapella } from "./CalcCapella";
import Cart from "common/Cart";
import OrderForm from "common/OrderForm";
import { pureUserData } from "shared";
import CircularProgress from "@mui/material/CircularProgress";
import ConvertString from "common/ConvertString";

import {
  SITE_URL,
  title,
  secondHeight,
  API_URL,
  steps,
  subsystems,
  hasMotor,
  lengths,
  axiosInstanceAuthorized,
  axiosInstancePublic,
} from "utils/constant";

import { FormatNumber } from "common/FormatNumber";
import { DateTimePicker } from "@mui/lab";
import { getBenefit, getInstall } from "utils/helpers";
import { useTranslation } from "react-i18next";

const dr = [", Slide left", ", Slide right", ", Slide center"];
// const dr = [''];
const compact_dr = ["3 Sliders", "2 Sliders"];
const openings = ["Left", "Right", "Center"];

const CustomTextField = styled(TextField)({
  margin: 0,
  textAlign: "center",
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      // display: 'none',
    },
  },
  "& .MuiInputBase-input": {
    padding: "4px",
  },
});

const TableCellItem = styled(TableCell)(({ theme }) => ({
  padding: 5,
  borderLeft: "1px solid #f0f0f0",
  borderRight: "1px solid #f0f0f0",
  marginBottom: 0,
  width: "20px",
  // backgroundColor: '#ffffff',
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  borderBottom: "1px solid #dfdfdf",
  borderRight: "1px solid #dfdfdf",
  // hide last border
  // '&:last-child td, &:last-child th': {
  //   border: 0,
  // },
}));

const StyledTableHead = styled(TableHead)(({ theme }) => ({
  backgroundColor: "#e0e0e0",
}));

const getHash = (a, b, c, d, e, f, g, h, i) => {
  return md5(
    a +
    "" +
    parseInt(b) +
    "" +
    parseInt(c) +
    "" +
    d +
    "" +
    e.toString() +
    "" +
    f.toString() +
    g.toString() +
    h.toString() +
    i.toString()
  );
};

var hash_list = {};
var render_cnt = 0;

const Add = (a, b, c, d, e, f, g, h, i) => {
  const key = getHash(
    a,
    b,
    c,
    d,
    e,
    f,
    a === 2 ? g : false,
    h,
    a === 8 ? i : 0
  );

  // if(!!b){
  if (key in hash_list) {
    const val = hash_list[key];
    hash_list[key] = {
      systemID: a,
      width: b,
      height: c,
      direction: d,
      extra_forte: e,
      slider_count: f,
      has_key: a === 2 ? g : false,
      count: val.count + 1,
      color: h,
      inout: i,
    };
  } else {
    hash_list[key] = {
      systemID: a,
      width: b,
      height: c,
      direction: d,
      extra_forte: e,
      slider_count: f,
      has_key: a === 2 ? g : false,
      count: 1,
      color: h,
      inout: i,
    };
  }
  // }
};

// const upperCaseFirstLetter = string =>
//  `${string.slice(0, 1).toUpperCase()}${string.slice(1)}`;

const lowerCaseAllWordsExceptFirstLetters = (string) =>
  string.replaceAll(
    /\S*/g,
    (word) => `${word.slice(0, 1)}${word.slice(1).toLowerCase()}`
  );

const Tables = ({
  products,
  optionals,
  modelCount,
  brandColor,
  setModelCount,
  setTP,
  setAction,
  setDisabled,
}) => {
  const isMobile = useMediaQuery("(max-width:426px)");
  const snap = useSnapshot(state);
  const { modelID, systemID, mode, setFlag } = useContext(AppContext);
  const cnt = ColumnCount();
  const [price, setPrice] = useState(0);
  const [weight, setWeight] = useState(0);
  const { t, i18n } = useTranslation();

  const user_install =
    mode === 0
      ? JSON.parse(localStorage.getItem("user")).user.install
      : localStorage.getItem("user_install");

  const F = (value) => {
    return parseInt(value * snap.length.unit + 0.5);
    // if (snap.length.unitName === 'inch') {
    //   return parseInt(value / 25.4);
    // }
    // else {
    //   return parseInt(value);
    // }
  };

  const setOptionalCount = (value, index) => {
    state.optionalCount[index] = value;
    // setFlag((prevFlag) => !prevFlag);
  };

  const hasOptional = (list, model) => {
    const product_list = list.toLowerCase().split(",");
    const model_name = model.toLowerCase();
    return product_list.includes(model_name);
  };

  const infoKey = (has_key) => {
    return has_key ? `, ${t('Has Key')}` : `, ${t('No Key')}`;
  }

  const handleChangeInstall = (value) => {
    state.install = value.install;
  };

  if (
    snap.columns.addedSubSystem[1] &&
    secondHeight[modelID] &&
    snap.walls.added[modelID === 6 ? 3 : 1] === false
  ) {
    // avantgarde added here
    Add(
      snap.columns.systemId[1],
      snap.subSystemWidth[1],
      snap.subSystemHeight[1],
      snap.columns.systemDirection[1],
      snap.columns.addedForte[1],
      snap.systemSliderCount[1],
      snap.columns.systemLock[1],
      snap.systemColorName[1],
      snap.systemSide[1]
    );
  }

  // for grande left/right subsystem
  if (
    modelID === 6 &&
    snap.columns.addedSubSystem[6] &&
    secondHeight[modelID] &&
    snap.walls.added[modelID === 6 ? 3 : 1] === false
  ) {
    // avantgarde added here
    if (snap.subSystemWidth[6] > 0 && snap.systemSliderCount[1] > 0) {
      Add(
        snap.columns.systemId[6],
        snap.subSystemWidth[6],
        snap.subSystemHeight[6],
        snap.columns.systemDirection[6],
        snap.columns.addedForte[6],
        snap.systemSliderCount[1],
        snap.columns.systemLock[6],
        snap.systemColorName[6],
        snap.systemSide[6]
      );
    }
  }

  // For only Roomy
  // if (
  //   modelID === 10 &&
  //   snap.columns.addedSubSystem[24 + 1] &&
  //   secondHeight[modelID]
  // ) {
  //   Add(
  //     snap.columns.systemId[24 + 1],
  //     snap.subSystemWidth[24 + 1],
  //     snap.subSystemHeight[24 + 1],
  //     snap.columns.systemDirection[24 + 1],
  //     snap.columns.addedForte[24 + 1],
  //     snap.systemSliderCount[24 + 1],
  //     snap.columns.systemLock[24 + 1],
  //     snap.systemColorName[24 + 1],
  //     snap.systemSide[24 + 1],
  //   );
  // }

  // For only Avantgarde
  if (
    (modelID === 9 || modelID === 11 || modelID === 16) &&
    snap.columns.addedSubSystem[1] &&
    secondHeight[modelID] &&
    snap.length.depth > 6000 &&
    snap.walls.added[1] === false
  ) {
    // prevent adding 0 width
    if (snap.subSystemWidth[6 + 1] > 0 && snap.systemSliderCount[6 + 1] > 0) {
      Add(
        snap.columns.systemId[6 + 1],
        snap.subSystemWidth[6 + 1],
        snap.subSystemHeight[6 + 1],
        snap.columns.systemDirection[6 + 1],
        snap.columns.addedForte[6 + 1],
        snap.systemSliderCount[6 + 1],
        snap.columns.systemLock[6 + 1],
        snap.systemColorName[6 + 1],
        snap.systemSide[6 + 1]
      );
    }
  }

  if (
    modelID !== 10 &&
    snap.columns.addedSubSystem[0] &&
    secondHeight[modelID] &&
    snap.walls.added[modelID === 6 ? 1 : 3] === false
  ) {
    // avantgarde added here
    Add(
      snap.columns.systemId[0],
      snap.subSystemWidth[0],
      snap.subSystemHeight[0],
      snap.columns.systemDirection[0],
      snap.columns.addedForte[0],
      snap.systemSliderCount[0],
      snap.columns.systemLock[0],
      snap.systemColorName[0],
      snap.systemSide[0]
    );
  }

  // for grande left/right subsystem
  if (
    modelID !== 10 && modelID === 6 &&
    snap.columns.addedSubSystem[7] &&
    secondHeight[modelID] &&
    snap.walls.added[modelID === 6 ? 1 : 3] === false
  ) {
    // avantgarde added here
    if (snap.subSystemWidth[7] > 0 && snap.systemSliderCount[0] > 0) {
      Add(
        snap.columns.systemId[7],
        snap.subSystemWidth[7],
        snap.subSystemHeight[7],
        snap.columns.systemDirection[7],
        snap.columns.addedForte[7],
        snap.systemSliderCount[0],
        snap.columns.systemLock[7],
        snap.systemColorName[7],
        snap.systemSide[7]
      );
    }
  }

  //For only Roomy
  if (
    modelID === 10 &&
    snap.columns.addedSubSystem[24 + 0] &&
    secondHeight[modelID]
  ) {
    Add(
      snap.columns.systemId[24 + 0],
      snap.subSystemWidth[24 + 0],
      snap.subSystemHeight[24 + 0],
      snap.columns.systemDirection[24 + 0],
      snap.columns.addedForte[24 + 0],
      snap.systemSliderCount[24 + 0],
      snap.columns.systemLock[24 + 0],
      snap.systemColorName[24 + 0],
      snap.systemSide[24 + 0]
    );
  }

  // For only Avantgarde
  if (
    (modelID === 9 || modelID === 11 || modelID === 16 || modelID === 20) &&
    snap.columns.addedSubSystem[0] &&
    secondHeight[modelID] &&
    snap.length.depth > 6000 &&
    snap.walls.added[3] === false
  ) {
    // prevent adding 0 width
    if (snap.subSystemWidth[6 + 0] > 0 && snap.systemSliderCount[6 + 0] > 0) {
      Add(
        snap.columns.systemId[6 + 0],
        snap.subSystemWidth[6 + 0],
        snap.subSystemHeight[6 + 0],
        snap.columns.systemDirection[6 + 0],
        snap.columns.addedForte[6 + 0],
        snap.systemSliderCount[6 + 0],
        snap.columns.systemLock[6 + 0],
        snap.systemColorName[6 + 0],
        snap.systemSide[6 + 0]
      );
    }
  }

  for (var i = secondHeight[modelID] ? 2 : 0; i < 48; i = i + 1) {
    if (secondHeight[modelID]) {
      if (
        snap.columns.addedSubSystem[i] === true &&
        i === 20 &&
        snap.columns.systemId[i] === 9
      ) {
        const tmp_cnt = snap.capellaCount;
        const tmp_unit = snap.capellaUnit;
        for (var j = 0; j < tmp_cnt; j = j + tmp_unit) {
          for (var k = 0; k < tmp_unit; k = k + 1)
            Add(
              snap.columns.systemId[i],
              snap.capellaSize[k],
              snap.subSystemHeight[i],
              snap.columns.systemDirection[i],
              snap.columns.addedForte[i],
              snap.systemSliderCount[i],
              snap.columns.systemLock[i],
              snap.systemColorName[i],
              snap.systemSide[i]
            );
        }
      } else {
        if (
          snap.columns.addedSubSystem[i] === true &&
          ((snap.columns.added[i - 2] === true && i !== 3) || i === 20) &&
          snap.subSystemWidth[i] > 0
        ) {
          console.log("snap.subSystemWidth[i]--", snap.subSystemWidth[i]);
          Add(
            snap.columns.systemId[i],
            snap.subSystemWidth[i],
            snap.subSystemHeight[i],
            snap.columns.systemDirection[i],
            snap.columns.addedForte[i],
            snap.systemSliderCount[i],
            snap.columns.systemLock[i],
            snap.systemColorName[i],
            snap.systemSide[i]
          );
        } else if (
          snap.columns.addedSubSystem[i] === true &&
          snap.subSystemWidth[i] > 0 &&
          i < 24 && i > 1 && i !== 7
        ) {
          if (modelID !== 6) { // prevent for grande
            // avantgarde added here
            Add(
              snap.columns.systemId[i],
              snap.subSystemWidth[i],
              snap.subSystemHeight[i],
              snap.columns.systemDirection[i],
              snap.columns.addedForte[i],
              snap.systemSliderCount[i],
              snap.columns.systemLock[i],
              snap.systemColorName[i],
              snap.systemSide[i]
            );
          }
        }

        //For only Roomy
      }
      if (
        modelID === 10 &&
        snap.columns.addedSubSystem[24 + i] === true &&
        snap.columns.added[i - 2] === true &&
        i !== 3
      ) {
        Add(
          snap.columns.systemId[24 + i],
          snap.subSystemWidth[24 + i],
          snap.subSystemHeight[24 + i],
          snap.columns.systemDirection[24 + i],
          snap.columns.addedForte[24 + i],
          snap.systemSliderCount[24 + i],
          snap.columns.systemLock[24 + i],
          snap.systemColorName[24 + i],
          snap.systemSide[24 + i]
        );
      }

      // For only Avantgarde, where size saved in list > 20
      if (
        (modelID === 9 || modelID === 11 || modelID === 16 || modelID === 20) &&
        snap.subSystemWidth[i] > 0 &&
        i >= 24
      ) {
        if (snap.subSystemWidth[i] > 0 && snap.systemSliderCount[i] > 0) {
          Add(
            snap.columns.systemId[i],
            snap.subSystemWidth[i],
            snap.subSystemHeight[i],
            snap.columns.systemDirection[i],
            snap.columns.addedForte[i],
            snap.systemSliderCount[i],
            snap.columns.systemLock[i],
            snap.systemColorName[i],
            snap.systemSide[i]
          );
        }
      }
    } else {
      if (
        snap.walls.added[snap.columns.side[i] - 1] === true &&
        snap.columns.systemId[i] < 9
      )
        continue;
      if (
        snap.columns.addedSubSystem[i] === true &&
        snap.subSystemWidth[i] > 0 &&
        snap.columns.systemId[i] === 9
      ) {
        const tmp_cnt = snap.capellaCount;
        const tmp_unit = snap.capellaUnit;
        for (var j = 0; j < tmp_cnt; j = j + tmp_unit) {
          for (var k = 0; k < tmp_unit; k = k + 1)
            Add(
              snap.columns.systemId[i],
              snap.capellaSize[k],
              snap.subSystemHeight[i],
              snap.columns.systemDirection[i],
              snap.columns.addedForte[i],
              snap.systemSliderCount[i],
              snap.columns.systemLock[i],
              snap.systemColorName[i],
              snap.systemSide[i]
            );
        }
      } else {
        if (
          snap.length.depth >
          (modelID === 1 || modelID === 4
            ? 3500
            : modelID === 2 || modelID === 8
              ? 4000
              : 4500) &&
          (i === 2 || i === 4)
        ) {
        } else {
          if (
            snap.columns.addedSubSystem[i] === true &&
            snap.subSystemWidth[i] > 0
          ) {
            if (!snap.columns.systemId[i] && snap.subSystemWidth[i] > 4000) {
              Add(
                snap.columns.systemId[i],
                snap.subSystemWidth[i] / 2,
                snap.subSystemHeight[i],
                snap.columns.systemDirection[i],
                snap.columns.addedForte[i],
                snap.systemSliderCount[i],
                snap.columns.systemLock[i],
                snap.systemColorName[i],
                snap.systemSide[i]
              );
              Add(
                snap.columns.systemId[i],
                snap.subSystemWidth[i] / 2,
                snap.subSystemHeight[i],
                snap.columns.systemDirection[i + 8],
                snap.columns.addedForte[i],
                snap.systemSliderCount[i],
                snap.columns.systemLock[i],
                snap.systemColorName[i],
                snap.systemSide[i]
              );
            } else {
              if (snap.subSystemWidth[i] > 0) {
                Add(
                  snap.columns.systemId[i],
                  snap.subSystemWidth[i],
                  snap.subSystemHeight[i],
                  snap.columns.systemDirection[i],
                  snap.columns.addedForte[i],
                  snap.systemSliderCount[i],
                  snap.columns.systemLock[i],
                  snap.systemColorName[i],
                  snap.systemSide[i]
                );
              }
            }
          }
        }
      }
      if (
        (modelID === 1 ||
          modelID === 4 ||
          // modelID === 2 ||  // disable extra Add for Panora
          // modelID === 8 || // disable extra Add for Panora Plus
          // modelID === 13 ||
          modelID === 15 ||
          modelID === 14) &&
        snap.columns.addedSubSystem[24 + i] === true &&
        snap.subSystemWidth[24 + i] > 0 &&
        snap.length.depth >
        (modelID === 1 || modelID === 4
          ? 3500
          : modelID === 2 || modelID === 8
            ? 4000
            : 4500)
      ) {
        if (
          !snap.columns.systemId[24 + i] &&
          snap.subSystemWidth[24 + i] > 4000
        ) {
          Add(
            snap.columns.systemId[24 + i],
            snap.subSystemWidth[24 + i] / 2,
            snap.subSystemHeight[24 + i],
            snap.columns.systemDirection[24 + i],
            snap.columns.addedForte[24 + i],
            snap.systemSliderCount[24 + i],
            snap.columns.systemLock[24 + i],
            snap.systemColorName[24 + i],
            snap.systemSide[24 + i]
          );
          Add(
            snap.columns.systemId[24 + i],
            snap.subSystemWidth[24 + i] / 2,
            snap.subSystemHeight[24 + i],
            snap.columns.systemDirection[24 + i + 8],
            snap.columns.addedForte[24 + i],
            snap.systemSliderCount[24 + i],
            snap.columns.systemLock[24 + i],
            snap.systemColorName[24 + i],
            snap.systemSide[24 + i]
          );
        } else {
          if (snap.subSystemWidth[24 + i] > 0) {
            Add(
              snap.columns.systemId[24 + i],
              snap.subSystemWidth[24 + i],
              snap.subSystemHeight[24 + i],
              snap.columns.systemDirection[24 + i],
              snap.columns.addedForte[24 + i],
              snap.systemSliderCount[24 + i],
              snap.columns.systemLock[24 + i],
              snap.systemColorName[24 + i],
              snap.systemSide[24 + i]
            );
          }
        }
      }
    }
  }

  const subsystem_list = [];
  const subsystem_mb_list = [];
  var subsystem_index = 1;
  render_cnt = render_cnt + 1;

  const sections = calcSection(
    modelID,
    snap.length.width,
    snap.length.depth,
    snap.systemVisible
  );

  for (const [key, sub] of Object.entries(hash_list)) {
    // grouping same subsystem with forte or not
    const hashKeyForte = getHash(
      sub.systemID,
      sub.width,
      sub.height,
      sub.direction,
      true,
      sub.slider_count,
      sub.systemID === 2 ? sub.has_key : false,
      sub.color,
      sub.systemID === 8 ? sub.inout : 0
    );
    const hashKeyNoForte = getHash(
      sub.systemID,
      sub.width,
      sub.height,
      sub.direction,
      false,
      sub.slider_count,
      sub.systemID === 2 ? sub.has_key : false,
      sub.color,
      sub.systemID === 8 ? sub.inout : 0
    );

    if (![hashKeyForte, hashKeyNoForte].includes(key)) {
      subsystem_list.push(
        <React.Fragment key={subsystem_index}>
          <StyledTableRow>
            <TableCellItem align="left">{++subsystem_index}</TableCellItem>
            <TableCellItem align="left" sx={{ fontWeight: "bolder" }}>
              {lowerCaseAllWordsExceptFirstLetters(
                subsystems[sub.systemID].title
              )}{" "}
              {sub.extra_forte ? null : null}
            </TableCellItem>
            <TableCellItem align="left">
              {!sub.systemID && sub.width > 4000
                ? F(sub.width - 50) / 2 + "x" + F(sub.height)
                : F(sub.width) + "x" + F(sub.height)}
            </TableCellItem>
            {/* <TableCellItem align="left" >
            {sub.systemID === 6 || sub.systemID === 9?
              <>{snap.systemColorName[20]}</>:null
            }
          </TableCellItem> */}
            <TableCellItem align="left">
              {subsystems[sub.systemID].option >= 2 ||
                subsystems[sub.systemID].option === 0
                ? `${sub.slider_count} ${t('Sliders')}`
                : null}
              {subsystems[sub.systemID].option >= 2
                ? t(dr[sub.direction])
                : sub.systemID === 0
                  ? compact_dr[parseInt(sub.direction)]
                  : null}
              {sub.systemID === 2
                ? infoKey(sub.has_key)
                : null}
              {sub.systemID === 7 ? ", Blade: " + sub.color : null}
              {sub.systemID === 6 || sub.systemID === 9
                ? `${t('Color')}: ` +
                (sub.systemID === 6 ? snap.forteColorName : sub.color)
                : null}
              {sub.systemID === 8
                ? sub.inout == 0
                  ? ", Outside"
                  : ", Inside"
                : null}
            </TableCellItem>
            <TableCellItem align="center">
              {(!sub.systemID && sub.width > 4000 ? sub.count * 2 : sub.count) /
                render_cnt}
            </TableCellItem>
          </StyledTableRow>
        </React.Fragment>
      );
    } else {
      if (hashKeyNoForte in hash_list && hashKeyForte in hash_list) {
        // skip subsystem having forte
        if (!sub.extra_forte) {
          const tmpCount = hash_list[hashKeyForte].count;
          const count = tmpCount + sub.count;
          subsystem_list.push(
            <React.Fragment key={subsystem_index}>
              <StyledTableRow>
                <TableCellItem align="left">{++subsystem_index}</TableCellItem>
                <TableCellItem align="left" sx={{ fontWeight: "bolder" }}>
                  {lowerCaseAllWordsExceptFirstLetters(
                    subsystems[sub.systemID].title
                  )}{" "}
                  {sub.extra_forte ? null : null}
                </TableCellItem>
                <TableCellItem align="left">
                  {!sub.systemID && sub.width > 4000
                    ? F(sub.width - 50) / 2 + "x" + F(sub.height)
                    : F(sub.width) + "x" + F(sub.height)}
                </TableCellItem>
                {/* <TableCellItem align="left" >
                {sub.systemID === 6 || sub.systemID === 9?
                  <>{snap.systemColorName[20]}</>:null
                }
              </TableCellItem> */}
                <TableCellItem align="left">
                  {subsystems[sub.systemID].option >= 2 ||
                    subsystems[sub.systemID].option === 0
                    ? `${sub.slider_count} ${t('Sliders')}`
                    : null}
                  {subsystems[sub.systemID].option >= 2
                    ? t(dr[sub.direction])
                    : sub.systemID === 0
                      ? compact_dr[parseInt(sub.direction)]
                      : null}
                  {sub.systemID === 2
                    ? infoKey(sub.has_key)
                    : null}
                  {sub.systemID === 7 ? ", Blade: " + sub.color : null}
                  {sub.systemID === 6 || sub.systemID === 9
                    ? `${t('Color')}: ` +
                    (sub.systemID === 6 ? snap.forteColorName : sub.color)
                    : null}
                  {sub.systemID === 8
                    ? sub.inout == 0
                      ? ", Outside"
                      : ", Inside"
                    : null}
                </TableCellItem>
                <TableCellItem align="center">
                  {(!sub.systemID && sub.width > 4000 ? count * 2 : count) /
                    render_cnt}
                </TableCellItem>
              </StyledTableRow>
            </React.Fragment>
          );
        }
      } else {
        subsystem_list.push(
          <React.Fragment key={subsystem_index}>
            <StyledTableRow>
              <TableCellItem align="left">{++subsystem_index}</TableCellItem>
              <TableCellItem align="left" sx={{ fontWeight: "bolder" }}>
                {lowerCaseAllWordsExceptFirstLetters(
                  subsystems[sub.systemID].title
                )}{" "}
                {sub.extra_forte ? null : null}
              </TableCellItem>
              <TableCellItem align="left">
                {!sub.systemID && sub.width > 4000
                  ? F(sub.width - 50) / 2 + "x" + F(sub.height)
                  : F(sub.width) + "x" + F(sub.height)}
              </TableCellItem>
              {/* <TableCellItem align="left" >
              {sub.systemID === 6 || sub.systemID === 9?
                <>{snap.systemColorName[20]}</>:null
              }
            </TableCellItem> */}
              <TableCellItem align="left">
                {subsystems[sub.systemID].option >= 2 ||
                  subsystems[sub.systemID].option === 0
                  ? `${sub.slider_count} ${t('Sliders')}`
                  : null}
                {subsystems[sub.systemID].option >= 2
                  ? t(dr[sub.direction])
                  : sub.systemID === 0
                    ? compact_dr[parseInt(sub.direction)]
                    : null}
                {sub.systemID === 2
                  ? infoKey(sub.has_key)
                  : null}
                {sub.systemID === 7 ? ", Blade: " + sub.color : null}
                {sub.systemID === 6 || sub.systemID === 9
                  ? `${t('Color')}: ` +
                  (sub.systemID === 6 ? snap.forteColorName : sub.color)
                  : null}
                {sub.systemID === 8
                  ? sub.inout == 0
                    ? ", Outside"
                    : ", Inside"
                  : null}
              </TableCellItem>
              <TableCellItem align="center">
                {(!sub.systemID && sub.width > 4000 ? sub.count * 2 : sub.count) /
                  render_cnt}
              </TableCellItem>
            </StyledTableRow>
          </React.Fragment>
        );
      }
    }

    // TODO: group forte on different subsystem


    if (sub.extra_forte) {
      subsystem_list.push(
        <React.Fragment key={subsystem_index}>
          <StyledTableRow>
            <TableCellItem align="left">{++subsystem_index}</TableCellItem>
            <TableCellItem align="left" sx={{ fontWeight: "bolder" }}>
              Forte
            </TableCellItem>
            <TableCellItem align="left">
              {OverForte(sub.width, sub.height)
                ? F(sub.width / 2)
                : F(sub.width)}
              {" x "} {F(sub.height)}
            </TableCellItem>
            {/* <TableCellItem align="left" >
            {sub.systemID === 6 || sub.systemID === 9?
              <>{snap.systemColorName[20]}</>:null
            }
          </TableCellItem> */}
            <TableCellItem align="left">
              {t('Color')}: {snap.forteColorName}
            </TableCellItem>
            <TableCellItem align="center">
              {OverForte(sub.width, sub.height)
                ? (sub.count * 2) / render_cnt
                : sub.count / render_cnt}
            </TableCellItem>
          </StyledTableRow>
        </React.Fragment>
      );
    }

    subsystem_mb_list.push(
      <Box
        key={1}
        sx={{
          border: "1px solid #fff",
          borderRadius: 1,
          marginBottom: 1,
        }}
      >
        <Stack direction="row">
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              width: "50%",
              padding: "5px",
              mb: "1px",
              bgcolor: "#f6f6f65e",
            }}
          >
            <Typography>{t('Product Name')}</Typography>
          </Box>
          <Box
            sx={{
              width: "50%",
              padding: "5px",
              mb: "1px",
              bgcolor: "#f6f6f65e",
            }}
          >
            <Stack
              direction="row"
              justifyContent="flex-start"
              alignItems="center"
            >
              {lowerCaseAllWordsExceptFirstLetters(
                subsystems[sub.systemID].title
              )}{" "}
              {sub.extra_forte ? null : null}
            </Stack>
          </Box>
        </Stack>
        <Stack direction="row">
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              width: "50%",
              padding: "5px",
              mb: "1px",
              bgcolor: "#f6f6f65e",
            }}
          >
            <Typography>{t('Dimension')}({snap.length.unitName})</Typography>
          </Box>
          <Box
            sx={{
              width: "50%",
              padding: "5px",
              mb: "1px",
              bgcolor: "#f6f6f65e",
            }}
          >
            <Stack
              direction="row"
              justifyContent="flex-start"
              alignItems="center"
            >
              {!sub.systemID && sub.width > 4000
                ? F(sub.width - 50) / 2 + "x" + F(sub.height)
                : F(sub.width) + "x" + F(sub.height)}
            </Stack>
          </Box>
        </Stack>
        <Stack direction="row">
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              width: "50%",
              padding: "5px",
              mb: "1px",
              bgcolor: "#f6f6f65e",
            }}
          >
            <Typography>{t('Description')}</Typography>
          </Box>
          <Box
            sx={{
              width: "50%",
              padding: "5px",
              mb: "1px",
              bgcolor: "#f6f6f65e",
            }}
          >
            <Stack
              direction="row"
              justifyContent="flex-start"
              alignItems="center"
            >
              {subsystems[sub.systemID].option >= 2 ||
                subsystems[sub.systemID].option === 0
                ? `${sub.slider_count} ${t('Sliders')}`
                : null}
              {subsystems[sub.systemID].option >= 2
                ? t(dr[sub.direction])
                : sub.systemID === 0
                  ? compact_dr[parseInt(sub.direction)]
                  : null}
              {sub.systemID === 2
                ? infoKey(sub.has_key)
                : null}
              {sub.systemID === 7 ? ", Blade: " + sub.color : null}
              {sub.systemID === 6 || sub.systemID === 9
                ? `${t('Color')}: ` +
                (sub.systemID === 6 ? snap.forteColorName : sub.color)
                : null}
              {sub.systemID === 8
                ? sub.inout == 0
                  ? ", Outside"
                  : ", Inside"
                : null}
            </Stack>
          </Box>
        </Stack>

        <Stack direction="row">
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              width: "50%",
              padding: "5px",
              mb: "1px",
              bgcolor: "#f6f6f65e",
            }}
          >
            <Typography>{t('Count')}</Typography>
          </Box>
          <Box
            sx={{
              width: "50%",
              padding: "5px",
              mb: "1px",
              bgcolor: "#f6f6f65e",
            }}
          >
            <Stack
              direction="row"
              justifyContent="flex-start"
              alignItems="center"
            >
              {(!sub.systemID && sub.width > 4000 ? sub.count * 2 : sub.count) /
                render_cnt}
            </Stack>
          </Box>
        </Stack>
      </Box>
    );

    if (sub.extra_forte) {
      subsystem_mb_list.push(
        <Box
          key={1}
          sx={{
            border: "1px solid #fff",
            borderRadius: 1,
            marginBottom: 1,
          }}
        >
          <Stack direction="row">
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                width: "50%",
                padding: "5px",
                mb: "1px",
                bgcolor: "#f6f6f65e",
              }}
            >
              <Typography>{t('Product Name')}</Typography>
            </Box>
            <Box
              sx={{
                width: "50%",
                padding: "5px",
                mb: "1px",
                bgcolor: "#f6f6f65e",
              }}
            >
              <Stack
                direction="row"
                justifyContent="flex-start"
                alignItems="center"
              >
                Forte
              </Stack>
            </Box>
          </Stack>
          <Stack direction="row">
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                width: "50%",
                padding: "5px",
                mb: "1px",
                bgcolor: "#f6f6f65e",
              }}
            >
              <Typography>{t('Dimension')}({snap.length.unitName})</Typography>
            </Box>
            <Box
              sx={{
                width: "50%",
                padding: "5px",
                mb: "1px",
                bgcolor: "#f6f6f65e",
              }}
            >
              <Stack
                direction="row"
                justifyContent="flex-start"
                alignItems="center"
              >
                {OverForte(sub.width, sub.height)
                  ? F(sub.width / 2)
                  : F(sub.width)}
                {" x "} {F(sub.height)}
              </Stack>
            </Box>
          </Stack>
          <Stack direction="row">
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                width: "50%",
                padding: "5px",
                mb: "1px",
                bgcolor: "#f6f6f65e",
              }}
            >
              <Typography>{t('Description')}</Typography>
            </Box>
            <Box
              sx={{
                width: "50%",
                padding: "5px",
                mb: "1px",
                bgcolor: "#f6f6f65e",
              }}
            >
              <Stack
                direction="row"
                justifyContent="flex-start"
                alignItems="center"
              >
                {t('Color')}: {snap.forteColorName}
              </Stack>
            </Box>
          </Stack>

          <Stack direction="row">
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                width: "50%",
                padding: "5px",
                mb: "1px",
                bgcolor: "#f6f6f65e",
              }}
            >
              <Typography>{t('Count')}</Typography>
            </Box>
            <Box
              sx={{
                width: "50%",
                padding: "5px",
                mb: "1px",
                bgcolor: "#f6f6f65e",
              }}
            >
              <Stack
                direction="row"
                justifyContent="flex-start"
                alignItems="center"
              >
                {OverForte(sub.width, sub.height)
                  ? (sub.count * 2) / render_cnt
                  : sub.count / render_cnt}
              </Stack>
            </Box>
          </Stack>
        </Box>
      );
    }
  }

  if (isMobile) {
    return (
      <>
        <Box
          key={1}
          sx={{
            border: "1px solid #fff",
            borderRadius: 1,
            marginBottom: 1,
          }}
        >
          <Stack direction="row">
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                width: "50%",
                padding: "5px",
                mb: "1px",
                bgcolor: "#f6f6f65e",
              }}
            >
              <Typography>{t('Product Name')}</Typography>
            </Box>
            <Box
              sx={{
                width: "50%",
                padding: "5px",
                mb: "1px",
                bgcolor: "#f6f6f65e",
              }}
            >
              <Stack
                direction="row"
                justifyContent="flex-start"
                alignItems="center"
              >
                {snap.isModel
                  ? lowerCaseAllWordsExceptFirstLetters(title[modelID])
                  : lowerCaseAllWordsExceptFirstLetters(
                    subsystems[systemID].title
                  )}
              </Stack>
            </Box>
          </Stack>
          <Stack direction="row">
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                width: "50%",
                padding: "5px",
                mb: "1px",
                bgcolor: "#f6f6f65e",
              }}
            >
              <Typography>{t('Dimension')}({snap.length.unitName})</Typography>
            </Box>
            <Box
              sx={{
                width: "50%",
                padding: "5px",
                mb: "1px",
                bgcolor: "#f6f6f65e",
              }}
            >
              <Stack
                direction="row"
                justifyContent="flex-start"
                alignItems="center"
              >
                {F(snap.length.width)} x {F(snap.length.depth)} x{" "}
                {F(snap.length.height1)}{" "}
                {secondHeight[modelID] ? " x " + snap.length.height2 : null}
              </Stack>
            </Box>
          </Stack>
          <Stack direction="row">
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                width: "50%",
                padding: "5px",
                mb: "1px",
                bgcolor: "#f6f6f65e",
              }}
            >
              <Typography>{t('Description')}</Typography>
            </Box>
            <Box
              sx={{
                width: "50%",
                padding: "5px",
                mb: "1px",
                bgcolor: "#f6f6f65e",
              }}
            >
              <Stack
                direction="row"
                justifyContent="flex-start"
                alignItems="center"
              >
                <Description
                  modelID={modelID}
                  systemID={systemID}
                  isModel={snap.isModel}
                />
              </Stack>
            </Box>
          </Stack>

          <Stack direction="row">
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                width: "50%",
                padding: "5px",
                mb: "1px",
                bgcolor: "#f6f6f65e",
              }}
            >
              <Typography>{t('Count')}</Typography>
            </Box>
            <Box
              sx={{
                width: "50%",
                padding: "5px",
                mb: "1px",
                bgcolor: "#f6f6f65e",
              }}
            >
              <Stack
                direction="row"
                justifyContent="flex-start"
                alignItems="center"
              >
                {sections}
              </Stack>
            </Box>
          </Stack>
        </Box>
        {subsystem_mb_list}
        {hasMotor[modelID] && false ? (
          <>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                width: "50%",
                padding: "5px",
                mb: "1px",
                bgcolor: "#f6f6f65e",
              }}
            >
              <Typography>{t('Motor')}</Typography>
            </Box>
            <Box
              sx={{
                width: "50%",
                padding: "5px",
                mb: "1px",
                bgcolor: "#f6f6f65e",
              }}
            >
              <Stack
                direction="row"
                justifyContent="flex-start"
                alignItems="center"
              >
                {t('YES')}
              </Stack>
            </Box>
          </>
        ) : null}

        {modelID > 0 &&
          secondHeight[modelID] &&
          ((snap.columns.addedSubSystem[0] &&
            snap.walls.added[modelID === 6 ? 1 : 3] === false) ||
            (snap.columns.addedSubSystem[1] &&
              snap.walls.added[modelID === 6 ? 3 : 1] === false)) ? (
          <Box>
            <Stack direction="row">
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  width: "50%",
                  padding: "5px",
                  mb: "1px",
                  bgcolor: "#f6f6f65e",
                }}
              >
                <Typography>{t('Product')}</Typography>
              </Box>
              <Box
                sx={{
                  width: "50%",
                  padding: "5px",
                  mb: "1px",
                  bgcolor: "#f6f6f65e",
                }}
              >
                <Stack
                  direction="row"
                  justifyContent="flex-start"
                  alignItems="center"
                >
                  {t('Triangle')}
                </Stack>
              </Box>
            </Stack>

            <Stack direction="row">
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  width: "50%",
                  padding: "5px",
                  mb: "1px",
                  bgcolor: "#f6f6f65e",
                }}
              >
                <Typography>{t('Dimension')}</Typography>
              </Box>
              <Box
                sx={{
                  width: "50%",
                  padding: "5px",
                  mb: "1px",
                  bgcolor: "#f6f6f65e",
                }}
              >
                <Stack
                  direction="row"
                  justifyContent="flex-start"
                  alignItems="center"
                >
                  {F(snap.length.depth) +
                    "X" +
                    F(snap.length.height2 - snap.length.height1)}
                </Stack>
              </Box>
            </Stack>

            <Stack direction="row">
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  width: "50%",
                  padding: "5px",
                  mb: "1px",
                  bgcolor: "#f6f6f65e",
                }}
              >
                <Typography>{t('Count')}</Typography>
              </Box>
              <Box
                sx={{
                  width: "50%",
                  padding: "5px",
                  mb: "1px",
                  bgcolor: "#f6f6f65e",
                }}
              >
                <Stack
                  direction="row"
                  justifyContent="flex-start"
                  alignItems="center"
                >
                  {snap.columns.addedSubSystem[0] &&
                    snap.walls.added[modelID === 6 ? 1 : 3] === false &&
                    snap.columns.addedSubSystem[1] &&
                    snap.walls.added[modelID === 6 ? 3 : 1] === false
                    ? 2
                    : 1}
                </Stack>
              </Box>
            </Stack>
          </Box>
        ) : null}

        {user_install === 0 ||
          user_install === "0" ||
          user_install === 2 ||
          user_install === "2" ? null : (
          <Stack direction="row">
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                width: "50%",
                padding: "5px",
                mb: "1px",
                bgcolor: "#f6f6f65e",
              }}
            >
              <Typography>{t('Installation')}</Typography>
            </Box>
            <Box
              sx={{
                width: "50%",
                padding: "5px",
                mb: "1px",
                bgcolor: "#f6f6f65e",
              }}
            >
              <Stack
                direction="row"
                justifyContent="flex-start"
                alignItems="center"
              >
                <ComfortBtn
                  {...{
                    label: "",
                    callback: handleChangeInstall,
                    str: "install",
                    status: snap.install,
                    brandColor: brandColor,
                    setTP: setTP,
                    setAction: setAction,
                  }}
                  size="small"
                />
              </Stack>
            </Box>
          </Stack>
        )}

        {optionals.map((one, i) =>
          hasOptional(one.list, title[modelID]) ? (
            <Box key={i}>
              <Stack direction="row">
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    width: "50%",
                    padding: "5px",
                    mb: "1px",
                    bgcolor: "#f6f6f65e",
                  }}
                >
                  <Typography>{t(one.title)}</Typography>
                </Box>
                <Box
                  sx={{
                    width: "50%",
                    padding: "5px",
                    mb: "1px",
                    bgcolor: "#f6f6f65e",
                  }}
                >
                  <Stack
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="center"
                  >
                    <ComfortBtn
                      key={i}
                      {...{
                        label: one.title,
                        str: one.title,
                        status: snap.optionals[i],
                        id: i,
                        size: "small",
                        brandColor: brandColor,
                        icon: one.icon,
                        setTP: setTP,
                        setAction: setAction,
                        setDisabled: setDisabled,
                      }}
                    />
                  </Stack>
                </Box>
              </Stack>
              <Stack direction="row">
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    width: "50%",
                    padding: "5px",
                    mb: "1px",
                    bgcolor: "#f6f6f65e",
                  }}
                >
                  <Typography>{t('Count')}</Typography>
                </Box>
                <Box
                  sx={{
                    width: "50%",
                    padding: "5px",
                    mb: "1px",
                    bgcolor: "#f6f6f65e",
                  }}
                >
                  <Stack
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="center"
                  >
                    {one.count && snap.optionals[i] ? (
                      <CustomTextField
                        variant="outlined"
                        size="small"
                        type="number"
                        value={snap.optionalCount[i]}
                        onChange={(e) => {
                          setDisabled(false);
                          setOptionalCount(e.target.value, i);
                        }}
                        fullWidth
                        inputProps={{
                          style: {
                            textAlign: "center",
                          },
                        }}
                      />
                    ) : (
                      snap.optionalCount[i]
                    )}
                  </Stack>
                </Box>
              </Stack>
            </Box>
          ) : null
        )}
      </>
    );
  } else {
    return (
      <>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <StyledTableHead>
            <StyledTableRow>
              <TableCellItem
                align="left"
                sx={{ fontWeight: "bolder", width: "3%" }}
              >
                No
              </TableCellItem>
              <TableCellItem
                align="left"
                sx={{ fontWeight: "bolder", width: "13%" }}
              >
                {t('Product Name')}
              </TableCellItem>
              <TableCellItem
                align="left"
                sx={{ fontWeight: "bolder", width: "13%" }}
              >
                {t('Dimension')} ({snap.length.unitName})
              </TableCellItem>
              {/* <TableCellItem  align="left" sx={{ fontWeight: 'bolder' }}>Structure Color</TableCellItem> */}
              <TableCellItem align="left" sx={{ fontWeight: "bolder" }}>
                {t('Description')}
              </TableCellItem>
              <TableCellItem
                align="center"
                sx={{ fontWeight: "bolder", width: "5%" }}
              >
                {t('Count')}
              </TableCellItem>
            </StyledTableRow>
          </StyledTableHead>
          <TableBody>
            <React.Fragment key={1}>
              <StyledTableRow>
                <TableCellItem align="left">1</TableCellItem>
                <TableCellItem align="left" sx={{ fontWeight: "bolder" }}>
                  {snap.isModel
                    ? lowerCaseAllWordsExceptFirstLetters(title[modelID])
                    : lowerCaseAllWordsExceptFirstLetters(
                      subsystems[systemID].title
                    )}
                </TableCellItem>
                {snap.isModel ? (
                  <TableCellItem align="left">
                    {F(snap.length.width)} x {F(snap.length.depth)} x{" "}
                    {F(snap.length.height1)}
                    {secondHeight[modelID]
                      ? " x " + F(snap.length.height2)
                      : null}
                  </TableCellItem>
                ) : (
                  <TableCellItem align="left">
                    {F(snap.length.width)} x {F(snap.length.depth)}
                  </TableCellItem>
                )}
                {/* <TableCellItem align="left" >{lowerCaseAllWordsExceptFirstLetters(snap.structure.colorName)} </TableCellItem> */}
                <TableCellItem align="left">
                  <Description
                    modelID={modelID}
                    systemID={systemID}
                    isModel={snap.isModel}
                  />
                </TableCellItem>
                <TableCellItem align="center">
                  {/* <TextField
                      size="small" 
                      type="number"
                      value={Number(modelCount)}
                      onChange={e=>{setModelCount(e.target.value)}}
                      fullWidth
                    /> */}
                  {sections}
                </TableCellItem>
              </StyledTableRow>
            </React.Fragment>

            {subsystem_list}

            {modelID > 0 &&
              secondHeight[modelID] &&
              ((snap.columns.addedSubSystem[0] &&
                snap.walls.added[modelID === 6 ? 1 : 3] === false) ||
                (snap.columns.addedSubSystem[1] &&
                  snap.walls.added[modelID === 6 ? 3 : 1] === false)) ? (
              <StyledTableRow>
                <TableCellItem align="left">{++subsystem_index}</TableCellItem>
                <TableCellItem align="left" sx={{ fontWeight: "bolder" }}>
                  {t('Triangle')}
                </TableCellItem>
                <TableCellItem align="left">
                  {F(snap.length.depth) +
                    "X" +
                    F(snap.length.height2 - snap.length.height1)}
                </TableCellItem>
                <TableCellItem align="left"></TableCellItem>
                <TableCellItem align="center" sx={{ fontWeight: "bolder" }}>
                  {snap.columns.addedSubSystem[0] &&
                    snap.walls.added[modelID === 6 ? 1 : 3] === false &&
                    snap.columns.addedSubSystem[1] &&
                    snap.walls.added[modelID === 6 ? 3 : 1] === false
                    ? 2
                    : 1}
                </TableCellItem>
              </StyledTableRow>
            ) : null}

            {snap.isModel ? (
              <>
                {hasMotor[modelID] && false ? (
                  <StyledTableRow>
                    <TableCellItem align="left">
                      {++subsystem_index}
                    </TableCellItem>
                    <TableCellItem align="left" sx={{ fontWeight: "bolder" }}>
                      {t('Motor')}
                    </TableCellItem>
                    <TableCellItem align="left"></TableCellItem>
                    {/* <TableCellItem align="left" ></TableCellItem> */}
                    <TableCellItem align="left"></TableCellItem>
                    <TableCellItem align="center" sx={{ fontWeight: "bolder" }}>
                      {t('Yes')}
                    </TableCellItem>
                  </StyledTableRow>
                ) : null}

                <StyledTableRow sx={{ backgroundColor: "#e0e0e0" }}>
                  <TableCellItem align="left"></TableCellItem>
                  <TableCellItem align="left" sx={{ fontWeight: "bolder" }}>
                    {t('Optional')}
                  </TableCellItem>
                  <TableCellItem align="left"></TableCellItem>
                  {/* <TableCellItem align="left" ></TableCellItem> */}
                  <TableCellItem align="left"></TableCellItem>
                  <TableCellItem align="center" sx={{ fontWeight: "bolder" }}>
                    {t('Count')}
                  </TableCellItem>
                </StyledTableRow>

                {optionals.map((one, i) =>
                  hasOptional(one.list, title[modelID]) ? (
                    <StyledTableRow key={i}>
                      <TableCellItem align="left">
                        {++subsystem_index}
                      </TableCellItem>
                      <TableCellItem align="left" sx={{ fontWeight: "bolder" }}>
                        {t(one.title)}
                      </TableCellItem>
                      <TableCellItem align="left">
                        {snap.optionals[i] &&
                          one.title.toLowerCase().includes("perimeter")
                          ? F((snap.length.width + snap.length.depth) * 2)
                          : null}
                      </TableCellItem>
                      {/* <TableCellItem align="left" ></TableCellItem> */}
                      <TableCellItem
                        align="right"
                        sx={{ fontWeight: "bolder", padding: 0 }}
                      >
                        <ComfortBtn
                          key={i}
                          {...{
                            label: one.title,
                            str: one.title,
                            status: snap.optionals[i],
                            id: i,
                            size: "small",
                            brandColor: brandColor,
                            icon: one.icon,
                            setTP: setTP,
                            setAction: setAction,
                            setDisabled: setDisabled,
                          }}
                        />
                      </TableCellItem>
                      <TableCellItem
                        align="left"
                        sx={{ p: 0, textAlign: "center" }}
                      >
                        {one.count && snap.optionals[i] ? (
                          <CustomTextField
                            variant="outlined"
                            size="small"
                            type="number"
                            value={snap.optionalCount[i]}
                            onChange={(e) => {
                              setDisabled(false);
                              setOptionalCount(e.target.value, i);
                            }}
                            fullWidth
                            inputProps={{
                              style: {
                                textAlign: "center",
                              },
                            }}
                          />
                        ) : (
                          snap.optionalCount[i]
                        )}
                      </TableCellItem>
                    </StyledTableRow>
                  ) : null
                )}

                {user_install === 0 || user_install === 2 ? null : (
                  <StyledTableRow>
                    <TableCellItem align="left">
                      {++subsystem_index}
                    </TableCellItem>
                    <TableCellItem align="left" sx={{ fontWeight: "bolder" }}>
                      {t('Installation')}
                    </TableCellItem>
                    <TableCellItem align="left"></TableCellItem>
                    {/* <TableCellItem align="left" ></TableCellItem> */}
                    <TableCellItem
                      align="right"
                      sx={{ fontWeight: "bolder", padding: 0 }}
                    >
                      <ComfortBtn
                        sx={{ zIndex: 999 }}
                        {...{
                          label: "install",
                          str: "install",
                          status: snap.install,
                          id: null,
                          size: "small",
                          brandColor: brandColor,
                          setTP: setTP,
                          setAction: setAction,
                          setDisabled: setDisabled,
                        }}
                      />
                    </TableCellItem>
                    <TableCellItem align="left"></TableCellItem>
                  </StyledTableRow>
                )}
              </>
            ) : null}
          </TableBody>
        </Table>
      </>
    );
  }
};

const calcSection = (modelID, width, depth, systemVisible) => {
  const groupC = [11, 9]; // 11: Pergola, 9: Pergola Avantgarde
  const groupD = [16]; // 16: Avantgarde M
  const groupA = [15]; // 15: Lenore

  const modelWidths = {
    11: 3500, // 11: Pergola
    9: 4000, // 9: Pergola Avantgarde
    16: 4000, // 16: Avantgarde M
  };
  
  const othersDepthMax = 4500;
  const modelDepths = {
    1: 3500,
    2: 4000,
    8: 4000,
    17: 3325, // 17: Cabbana, 
    4: 3325, // 4: Lanai
    13: 4275, // 13: Cabbana X
    14: 4275, // 14: Lanai X
    15: 6040, // 15: Lenore
  };

  const getDepthMax = (modelID) => {
    if (modelID in modelDepths) return modelDepths[modelID];
    // to exclude from conditions
    if (modelID in modelWidths) return -1;
    return othersDepthMax
  };

  const getWidthMax = (modelID) => {
    return lengths[modelID].mid_length_limit[0];
  };

  if (depth <= getDepthMax(modelID)) {
    if (width <= lengths[modelID].mid_length_limit[0]) return 1;
    if (groupA.includes(modelID)) {
      return Math.ceil(width / lengths[modelID].mid_length_limit[0]);
    }
    return 2;
  } else if (groupC.includes(modelID)) {
    return Math.ceil(width / getWidthMax(modelID));
  } else if (groupD.includes(modelID)) {
    return Math.ceil(width / getWidthMax(modelID)) * 2;
  } else {
    if (width <= lengths[modelID].mid_length_limit[0]) return 2;
    return systemVisible.filter((item) => item === true).length;
  }
};

const OverviewTable = () => {
  const snap = useSnapshot(state);
  const { isDark } = useContext(ConfigContext);
  const { t, i18n } = useTranslation();

  const {
    modelID,
    systemID,
    activeStep,
    openSendForm,
    setOpenSendForm,
    modelCount,
    setModelCount,
    brandColor,
    mode,
    flag,
    setFlag,
    showprice,
    totalPrice,
    setTP,
    optionals,
    setOptionals,
  } = useContext(AppContext);

  const user =
    mode === 0
      ? JSON.parse(localStorage.getItem("user")).user
      : JSON.parse(localStorage.getItem("embed_user"));
  const user_install =
    mode === 0
      ? JSON.parse(localStorage.getItem("user")).user.install
      : localStorage.getItem("user_install");

  const product_prices =
    user.prices === undefined || user.prices === null
      ? {}
      : JSON.parse(user.prices);

  const cnt = ColumnCount();
  const [loading, setLoading] = useState(true);
  const [render, setRender] = useState(0);

  const api_url =
    mode === 1 ? localStorage.getItem("api_url") : user.extra_link_user;
  const thanks_url = mode === 1 ? localStorage.getItem("thanks") : user.thanks;

  const [products, setProducts] = useState([]);
  const [open, setOpen] = useState(false);
  const [action, setAction] = useState("Overview Clicked");
  const [status, setStatus] = useState(true);
  const [optionalPrice, setOP] = useState(-1);
  const [productPrice, setPP] = useState(0);
  const [totalWeight, setTW] = useState(0);
  const [disabled, setDisabled] = useState(true);
  const navigate = useNavigate();

  const embed_key =
    mode === 1 ? localStorage.getItem("embed_key") : user.embed_key;

  const sections = calcSection(
    modelID,
    snap.length.width,
    snap.length.depth,
    snap.systemVisible
  );
  const userId =
    mode === 1
      ? localStorage.getItem("id")
      : JSON.parse(localStorage.getItem("user")).user.id;

  const getProductId = (product_name) => {
    const product = products.filter(
      (product) => product.title.toLowerCase() === product_name.toLowerCase()
    );
    return product.length > 0 ? product[0].id : -1;
  };

  const all_subsystems = [];
  if (render_cnt) {
    if (snap.isModel) {
      const benefit = getBenefit(title[modelID], product_prices);
      const install = getInstall(title[modelID], product_prices);
      if (!secondHeight[modelID]) {
        all_subsystems.push({
          product_id: getProductId(title[modelID]),
          model: title[modelID],
          width: snap.length.width,
          depth: snap.length.depth,
          front_height: snap.length.height1,
          back_height: secondHeight[modelID]
            ? snap.length.height2
            : snap.length.height1,
          structure_color: snap.structure.colorName,
          section: sections,
          benefit: benefit,
          install: install,
        });
      } else {
        if (modelID === 6 || modelID === 10) {
          all_subsystems.push({
            product_id: getProductId(title[modelID]),
            model: title[modelID],
            width: snap.length.width,
            depth: snap.length.depth,
            front_height: snap.length.height1,
            back_height: secondHeight[modelID]
              ? snap.length.height2
              : snap.length.height1,
            structure_color: snap.structure.colorName,
            glassType: snap.blades.colorName,
            benefit: benefit,
            install: install,
          });
        } else if (
          modelID === 1 ||
          modelID === 4 ||
          modelID === 13 ||
          modelID === 15 ||
          modelID === 14
        ) {
          all_subsystems.push({
            product_id: getProductId(title[modelID]),
            model: title[modelID],
            width: snap.length.width,
            depth: snap.length.depth,
            front_height: snap.length.height1,
            back_height: secondHeight[modelID]
              ? snap.length.height2
              : snap.length.height1,
            structure_color: snap.structure.colorName,
            blade_color: snap.blades.colorName,
            benefit: benefit,
            install: install,
          });
        } else if (modelID === 9 || modelID === 11 || modelID === 16 || modelID === 20) {
          all_subsystems.push({
            product_id: getProductId(title[modelID]),
            model: title[modelID],
            width: snap.length.width,
            depth: snap.length.depth,
            front_height: snap.length.height1,
            back_height: secondHeight[modelID]
              ? snap.length.height2
              : snap.length.height1,
            structure_color: snap.structure.colorName,
            curtain_color: snap.systemColorName[23],
            benefit: benefit,
            install: install,
          });
        } else {
          all_subsystems.push({
            product_id: getProductId(title[modelID]),
            model: title[modelID],
            width: snap.length.width,
            depth: snap.length.depth,
            front_height: snap.length.height1,
            back_height: secondHeight[modelID]
              ? snap.length.height2
              : snap.length.height1,
            structure_color: snap.structure.colorName,
            benefit: benefit,
            install: install,
          });
        }
      }
      for (const [key, sub] of Object.entries(hash_list)) {
        const benefit = getBenefit(subsystems[sub.systemID].title, product_prices);
        const install = getInstall(subsystems[sub.systemID].title, product_prices);
        const benefit_f = getBenefit("Forte", product_prices);
        const install_f = getInstall("Forte", product_prices);
        if (sub.systemID === 9) {
          all_subsystems.push({
            product_id: getProductId(subsystems[sub.systemID].title),
            model: subsystems[sub.systemID].title,
            width: sub.width,
            height: sub.height,
            count: sub.count / render_cnt,
            color: sub.color,
            benefit: benefit,
            install: install,
          });
        } else if (sub.systemID === 2) {
          all_subsystems.push({
            product_id: getProductId(subsystems[sub.systemID].title),
            model: subsystems[sub.systemID].title,
            width: sub.width,
            height: sub.height,
            count:
              (!sub.systemID && sub.width > 4000 ? sub.count * 2 : sub.count) /
              render_cnt,
            slide: !sub.systemID ? (!sub.direction ? 3 : 2) : sub.slider_count,
            opening: openings[sub.direction],
            overforte: sub.extra_forte,
            key: sub.has_key,
            benefit: benefit,
            install: install,
          });
          if (sub.extra_forte) {
            all_subsystems.push({
              product_id: getProductId("Forte"),
              model: "FORTE",
              width: OverForte(sub.width, sub.height)
                ? sub.width / 2
                : sub.width,
              height: sub.height,
              count:
                (OverForte(sub.width, sub.height) ? sub.count * 2 : sub.count) /
                render_cnt,
              color: snap.forteColorName,
              benefit: benefit_f,
              install: install_f,
            });
          }
        } else if (sub.systemID === 6) {
          all_subsystems.push({
            product_id: getProductId("Forte"),
            model: "FORTE",
            width: OverForte(sub.width, sub.height) ? sub.width / 2 : sub.width,
            height: sub.height,
            count:
              (OverForte(sub.width, sub.height) ? sub.count * 2 : sub.count) /
              render_cnt,
            color: snap.forteColorName,
            benefit: benefit_f,
            install: install_f,
          });
        } else if (sub.systemID === 8) {
          all_subsystems.push({
            product_id: getProductId(subsystems[sub.systemID].title),
            model: subsystems[sub.systemID].title,
            width: sub.width,
            height: sub.height,
            count: sub.count / render_cnt,
            inout: sub.inout == 0 ? "Out" : "In",
            opening: openings[sub.direction],
            benefit: benefit,
            install: install,
          });
        } else {
          all_subsystems.push({
            product_id: getProductId(subsystems[sub.systemID].title),
            model: subsystems[sub.systemID].title,
            width:
              !sub.systemID && sub.width > 4000
                ? parseInt(sub.width - 50) / 2
                : sub.width,
            height: sub.height,
            count:
              (!sub.systemID && sub.width > 4000 ? sub.count * 2 : sub.count) /
              render_cnt,
            slide: !sub.systemID ? (!sub.direction ? 3 : 2) : sub.slider_count,
            opening: openings[sub.direction],
            overforte: sub.extra_forte,
            benefit: benefit,
            install: install,
          });
          if (sub.extra_forte) {
            all_subsystems.push({
              product_id: getProductId("Forte"),
              model: "FORTE",
              width: OverForte(sub.width, sub.height)
                ? sub.width / 2
                : sub.width,
              height: sub.height,
              count:
                (OverForte(sub.width, sub.height) ? sub.count * 2 : sub.count) /
                render_cnt,
              color: snap.forteColorName,
              benefit: benefit_f,
              install: install_f,
            });
          }
        }
      }
      if (
        modelID > 0 &&
        secondHeight[modelID] &&
        ((snap.columns.addedSubSystem[0] &&
          snap.walls.added[modelID === 6 ? 1 : 3] === false) ||
          (snap.columns.addedSubSystem[1] &&
            snap.walls.added[modelID === 6 ? 3 : 1] === false))
      )
        all_subsystems.push({
          model: "TRIANGLE",
          width: snap.length.depth,
          height: snap.length.height2 - snap.length.height1,
          count:
            snap.columns.addedSubSystem[0] &&
              snap.walls.added[modelID === 6 ? 1 : 3] === false &&
              snap.columns.addedSubSystem[1] &&
              snap.walls.added[modelID === 6 ? 3 : 1] === false
              ? 2
              : 1,
        });
    } else {
      all_subsystems.push({
        product_id: getProductId(subsystems[systemID].title),
        model: subsystems[systemID].title,
        width: snap.length.width,
        height: snap.length.depth,
        structure_color: snap.structure.colorName,
        slide: !systemID
          ? !snap.columns.systemDirection[0]
            ? 3
            : 2
          : snap.systemSliderCount[0],
        opening: openings[snap.columns.systemDirection[0]],
        benefit: getBenefit(subsystems[systemID].title, product_prices),
        install: getInstall(subsystems[systemID].title, product_prices),
      });
    }
  }

  const handleClose = () => {
    setOpenSendForm(false);
  };

  const calcPrice = async (products, optionals) => {
    const lang = i18n.language;
    setLoading(true);
    const _all_subsystems = [];
    const sections = calcSection(
      modelID,
      snap.length.width,
      snap.length.depth,
      snap.systemVisible
    );
    const _user = {
      email: user.email,
      firstName: user.first_name,
      lastName: user.last_name,
      embed: user.embed_key,
      benefit: user.benefit,
      discount: user.discount,
      shipping_price: user.shipping_price,
      campaign: user.campaign_per_product,
      extra_formula: user.extra_benefit,
    };

    if (snap.isModel) {
      const benefit = getBenefit(title[modelID], product_prices);
      const install = getInstall(title[modelID], product_prices);
      if (!secondHeight[modelID]) {
        _all_subsystems.push({
          product_id: products.filter(
            (product) =>
              product.title.toLowerCase() === title[modelID].toLowerCase()
          )[0].id,
          model: title[modelID],
          width: snap.length.width,
          depth: snap.length.depth,
          front_height: snap.length.height1,
          back_height: secondHeight[modelID]
            ? snap.length.height2
            : snap.length.height1,
          structure_color: snap.structure.colorName,
          section: sections,
          benefit: benefit,
          install: install,
        });
      } else {
        if (modelID === 6 || modelID === 10) {
          _all_subsystems.push({
            product_id: products.filter(
              (product) =>
                product.title.toLowerCase() === title[modelID].toLowerCase()
            )[0].id,
            model: title[modelID],
            width: snap.length.width,
            depth: snap.length.depth,
            front_height: snap.length.height1,
            back_height: secondHeight[modelID]
              ? snap.length.height2
              : snap.length.height1,
            structure_color: snap.structure.colorName,
            glassType: snap.blades.colorName,
            benefit: benefit,
            install: install,
          });
        } else if (
          modelID === 1 ||
          modelID === 4 ||
          modelID === 13 ||
          modelID === 15 ||
          modelID === 14
        ) {
          _all_subsystems.push({
            // product_id: getProductId(title[modelID]),
            product_id: products.filter(
              (product) =>
                product.title.toLowerCase() === title[modelID].toLowerCase()
            )[0].id,
            model: title[modelID],
            width: snap.length.width,
            depth: snap.length.depth,
            front_height: snap.length.height1,
            back_height: secondHeight[modelID]
              ? snap.length.height2
              : snap.length.height1,
            structure_color: snap.structure.colorName,
            blade_color: snap.blades.colorName,
            benefit: benefit,
            install: install,
          });
        } else if (modelID === 9 || modelID === 11 || modelID === 16 || modelID === 20) {
          _all_subsystems.push({
            product_id: products.filter(
              (product) =>
                product.title.toLowerCase() === title[modelID].toLowerCase()
            )[0].id,
            model: title[modelID],
            width: snap.length.width,
            depth: snap.length.depth,
            front_height: snap.length.height1,
            back_height: secondHeight[modelID]
              ? snap.length.height2
              : snap.length.height1,
            structure_color: snap.structure.colorName,
            curtain_color: snap.systemColorName[23],
            benefit: benefit,
            install: install,
          });
        } else {
          _all_subsystems.push({
            // product_id: getProductId(title[modelID]),
            product_id: products.filter(
              (product) =>
                product.title.toLowerCase() === title[modelID].toLowerCase()
            )[0].id,
            model: title[modelID],
            width: snap.length.width,
            depth: snap.length.depth,
            front_height: snap.length.height1,
            back_height: secondHeight[modelID]
              ? snap.length.height2
              : snap.length.height1,
            structure_color: snap.structure.colorName,
            benefit: benefit,
            install: install,
          });
        }
      }

      for (const [key, sub] of Object.entries(hash_list)) {
        const benefit = getBenefit(subsystems[sub.systemID].title, product_prices);
        const install = getInstall(subsystems[sub.systemID].title, product_prices);
        const benefit_f = getBenefit("Forte", product_prices);
        const install_f = getInstall("Forte", product_prices);
        if (sub.systemID === 9) {
          _all_subsystems.push({
            // product_id: getProductId(subsystems[sub.systemID].title),
            product_id: products.filter(
              (product) =>
                product.title.toLowerCase() ===
                subsystems[sub.systemID].title.toLowerCase()
            )[0].id,
            model: subsystems[sub.systemID].title,
            width: sub.width,
            height: sub.height,
            count: sub.count / render_cnt,
            color: sub.color,
            benefit: benefit,
            install: install,
          });
        } else if (sub.systemID === 2) {
          _all_subsystems.push({
            // product_id: getProductId(subsystems[sub.systemID].title),
            product_id: products.filter(
              (product) =>
                product.title.toLowerCase() ===
                subsystems[sub.systemID].title.toLowerCase()
            )[0].id,
            model: subsystems[sub.systemID].title,
            width: sub.width,
            height: sub.height,
            count:
              (!sub.systemID && sub.width > 4000 ? sub.count * 2 : sub.count) /
              render_cnt,
            slide: !sub.systemID ? (!sub.direction ? 3 : 2) : sub.slider_count,
            opening: openings[sub.direction],
            overforte: sub.extra_forte,
            key: sub.has_key,
            benefit: benefit,
            install: install,
          });

          if (sub.extra_forte) {
            _all_subsystems.push({
              // product_id: getProductId('Forte'),
              product_id: products.filter(
                (product) => product.title.toLowerCase() === "forte"
              )[0].id,
              model: "FORTE",
              width: OverForte(sub.width, sub.height)
                ? sub.width / 2
                : sub.width,
              height: sub.height,
              count:
                (OverForte(sub.width, sub.height) ? sub.count * 2 : sub.count) /
                render_cnt,
              color: snap.forteColorName,
              benefit: benefit_f,
              install: install_f,
            });
          }
        } else if (sub.systemID === 6) {
          _all_subsystems.push({
            product_id: products.filter(
              (product) => product.title.toLowerCase() === "forte"
            )[0].id,
            model: "FORTE",
            width: OverForte(sub.width, sub.height) ? sub.width / 2 : sub.width,
            height: sub.height,
            count:
              (OverForte(sub.width, sub.height) ? sub.count * 2 : sub.count) /
              render_cnt,
            color: snap.forteColorName,
            benefit: benefit_f,
            install: install_f,
          });
        } else if (sub.systemID === 8) {
          _all_subsystems.push({
            product_id: products.filter(
              (product) =>
                product.title.toLowerCase() ===
                subsystems[sub.systemID].title.toLowerCase()
            )[0].id,
            model: subsystems[sub.systemID].title,
            width: sub.width,
            height: sub.height,
            count: sub.count / render_cnt,
            inout: sub.inout == 0 ? "Out" : "In",
            opening: openings[sub.direction],
            benefit: benefit,
            install: install,
          });
        } else {
          _all_subsystems.push({
            product_id: products.filter(
              (product) =>
                product.title.toLowerCase() ===
                subsystems[sub.systemID].title.toLowerCase()
            )[0].id,
            model: subsystems[sub.systemID].title,
            width:
              !sub.systemID && sub.width > 4000
                ? (sub.width - 50) / 2
                : sub.width,
            height: sub.height,
            count:
              (!sub.systemID && sub.width > 4000 ? sub.count * 2 : sub.count) /
              render_cnt,
            slide: !sub.systemID ? (!sub.direction ? 3 : 2) : sub.slider_count,
            opening: openings[sub.direction],
            overforte: sub.extra_forte,
            benefit: benefit,
            install: install,
          });
          if (sub.extra_forte) {
            _all_subsystems.push({
              product_id: products.filter(
                (product) => product.title.toLowerCase() === "forte"
              )[0].id,
              model: "FORTE",
              width: OverForte(sub.width, sub.height)
                ? sub.width / 2
                : sub.width,
              height: sub.height,
              count:
                (OverForte(sub.width, sub.height) ? sub.count * 2 : sub.count) /
                render_cnt,
              color: snap.forteColorName,
              benefit: benefit,
              install: install,
            });
          }
        }
      }

      if (
        modelID > 0 &&
        secondHeight[modelID] &&
        ((snap.columns.addedSubSystem[0] &&
          snap.walls.added[modelID === 6 ? 1 : 3] === false) ||
          (snap.columns.addedSubSystem[1] &&
            snap.walls.added[modelID === 6 ? 3 : 1] === false))
      )
        _all_subsystems.push({
          model: "TRIANGLE",
          width: snap.length.depth,
          height: snap.length.height2 - snap.length.height1,
          count:
            snap.columns.addedSubSystem[0] &&
              snap.walls.added[modelID === 6 ? 1 : 3] === false &&
              snap.columns.addedSubSystem[1] &&
              snap.walls.added[modelID === 6 ? 3 : 1] === false
              ? 2
              : 1,
        });
    } else {
      const benefit = getBenefit(subsystems[systemID].title, product_prices);
      const install = getInstall(subsystems[systemID].title, product_prices);
      _all_subsystems.push({
        product_id: products.filter(
          (product) =>
            product.title.toLowerCase() ===
            subsystems[systemID].title.toLowerCase()
        )[0].id,
        model: subsystems[systemID].title,
        width: snap.length.width,
        height: snap.length.depth,
        structure_color: snap.structure.colorName,
        slide: !systemID
          ? !snap.columns.systemDirection[0]
            ? 3
            : 2
          : snap.systemSliderCount[0],
        opening: openings[snap.columns.systemDirection[0]],
        benefit: benefit,
        install: install,
      });
    }

    const _optionals = [];
    optionals.map((optional, index) => {
      if (snap.optionals[index] && snap.isModel) {
        if (optional.title.toLowerCase().includes("perimeter")) {
          _optionals.push({
            name: optional.title,
            iscount: snap.optionals[index],
            count: snap.optionals[index]
              ? parseInt(snap.optionalCount[index])
              : 0,
            length: (snap.length.width + snap.length.depth) * 2,
          });
        } else {
          _optionals.push({
            name: optional.title,
            iscount: snap.optionals[index],
            count: snap.optionals[index]
              ? parseInt(snap.optionalCount[index])
              : 0,
          });
        }
      }
    });

    const data1 = {
      optional: _optionals,
      products: _all_subsystems,
      action: action,
      modelname: snap.isModel ? title[modelID] : subsystems[systemID].title,
      width: snap.length.width,
      depth: snap.length.depth,
      front_height: snap.length.height1,
      back_height: secondHeight[modelID]
        ? snap.length.height2
        : snap.length.height1,
      user: _user,
      isTexture: snap.isTexture ? 1 : 0,
      install:
        snap.install == 1
          ? "With"
          : user_install == 2
            ? "Without But Calculated"
            : "Without",
      lang : lang      
    };

    console.log("data1", data1);

    try {
      const response = await axios
        .post(user.price_link || "https://schildr.com/price", data1, {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
      });
      console.log("response.data", response.data);
      // setOptionals(optionals);
      setTP(response.data.total_price || 0);
      setTW(response.data.total_weight || 0);
      setOP(response.data.total_optionals_price || 0);
      setPP(response.data.total_products_price || 0);
      setLoading(false);
    } catch (err) {
      toastError(`Price API: ${err.message}`);
    }
  };

  const handleSend = (formData) => {
    const lang = i18n.language;
    axiosInstanceAuthorized
      .get(API_URL + "optional/get")
      .then((response) => {
        const _user = {
          UserName: mode === 1 ? localStorage.getItem("user_id") : user.user_id,
          firstName:
            mode === 1 ? localStorage.getItem("first_name") : user.first_name,
          lastName:
            mode === 1 ? localStorage.getItem("last_name") : user.last_name,
          embed:
            mode === 1 ? localStorage.getItem("embed_key") : user.embed_key,
          benefit: mode === 1 ? localStorage.getItem("benefit") : user.benefit,
          discount:
            mode === 1 ? localStorage.getItem("discount") : user.discount,
          shipping_price: user.shipping_price,
          campaign: user.campaign_per_product,
          extra_formula: user.extra_benefit,
        };

        const _client = {
          firstName: formData.firstName,
          lastName: formData.lastName,
          email: formData.email,
          phone: formData.phoneNumber,
          zipCode: formData.zipcode,
          city: formData.city,
          address: formData.address,
        };

        const _optionals = [];

        response.data.optionals.map((optional, index) => {
          if (snap.optionals[index] && snap.isModel) {
            if (optional.title.toLowerCase().includes("perimeter")) {
              _optionals.push({
                name: optional.title,
                iscount: snap.optionals[index],
                count: snap.optionals[index]
                  ? parseInt(snap.optionalCount[index])
                  : 0,
                length: (snap.length.width + snap.length.depth) * 2,
              });
            } else {
              _optionals.push({
                name: optional.title,
                iscount: snap.optionals[index],
                count: snap.optionals[index]
                  ? parseInt(snap.optionalCount[index])
                  : 0,
              });
            }
          }
        });

        const _unit = snap.length.unitName;
        const _message = formData.message;
        const _findInfo = formData.info;
        const _main_product = snap.isModel
          ? title[modelID]
          : subsystems[systemID].title;

        const data = {
          client_id: -11,
          model_name: snap.isModel
            ? title[modelID]
            : subsystems[systemID].title,
          unit: snap.length.unitName,
          width: parseInt(snap.length.width * snap.length.unit + 0.5),
          depth: parseInt(snap.length.depth * snap.length.unit + 0.5),
          height1: parseInt(snap.length.height1 * snap.length.unit + 0.5),
          height2: parseInt(snap.length.height2 * snap.length.unit + 0.5),
          cover_color: snap.structure.color,
          glass_texture: snap.blades.textureType,
          light: snap.hasLight,
          number_of_columns: cnt,
          added_columns: JSON.stringify(snap.columns.added),
          added_column_position: JSON.stringify(snap.columns.pos),
          install: 0,
          inst: 0,
          ledprice: 0,
          weight: 0,
          data: snap,
          user_id: userId,
          email: formData.email,
          benefit: getBenefit(_main_product, product_prices),
          install: getInstall(_main_product, product_prices),
          lang: lang
        };

        axiosInstanceAuthorized
          .post(API_URL + "order/add", data)
          .then((response) => {
            const data1 = {
              //old request

              modelName: snap.isModel
                ? title[modelID]
                : subsystems[systemID].title,
              // unit: snap.length.unitName,
              width: parseInt(snap.length.width * snap.length.unit + 0.5),
              depth: parseInt(snap.length.depth * snap.length.unit + 0.5),
              height1: parseInt(snap.length.height1 * snap.length.unit + 0.5),
              height2: parseInt(snap.length.height2 * snap.length.unit + 0.5),
              coverColor: snap.structure.color,
              isTexture: snap.isTexture,
              glassColor: snap.blades.color,
              glassTexture: snap.blades.textureType,
              Light: snap.hasLight,
              numberOfColumns: cnt,
              addedColumns: JSON.stringify(snap.columns.added),
              addedColumnPosition: JSON.stringify(snap.columns.pos),
              firstName: formData.firstName,
              lastName: formData.lastName,
              email: formData.email,
              city: formData.city,
              // findInfo: formData.info,
              zipCode: formData.zipcode,
              address: formData.address,
              phoneNumber: formData.phoneNumber,
              // message: formData.message,
              install:
                snap.install == 1
                  ? "With"
                  : user_install == 2
                    ? "Without But Calculated"
                    : "Without",
              modelCount: modelCount,
              embed_key: embed_key,

              //new request
              user: _user,
              client: _client,
              optional: _optionals,
              unit: _unit,
              message: _message,
              findInfo: _findInfo,
              main_product: _main_product,
              products: all_subsystems,
              // section: secondHeight[modelID]?0:sections,
              viewLink: SITE_URL + "/view/" + response.data.data_id,
              lang: lang
            };

            fetch(api_url, {
              // Enter your IP address here
              method: "POST",
              mode: "cors",
              body: JSON.stringify(data1), // body data type must match "Content-Type" header
            })
              .then((response) => response.json())
              .then((data) => {
                state.responseMsg = data;
                state.datasent = 1;
                var a = document.createElement("a");
                a.href = thanks_url;
                a.target = "_top";
                a.click();
                window.location.href = thanks_url;
                toastSuccess(
                  "Your form has been submitted successfully!",
                  brandColor
                );
                setOpenSendForm(false);
              })
              .catch((error) => {
                toastError(
                  "Network Error! Please check internet connection or your setting"
                );
                setOpenSendForm(false);
                state.datasent = -1;
              });

            toastSuccess("History added successfully!", brandColor);
          })
          .catch((err) => {
            toastError(err.message);
          });
      })
      .catch((err) => {
        if (err.message === "Network Error") {
          toast.error(err.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      });

    //   fetch(api_url, {  // Enter your IP address here

    //     method: 'POST',
    //     mode: 'cors',
    //     body: JSON.stringify(data1) // body data type must match "Content-Type" header

    //   })
    //     .then(response => response.json())
    //     .then(data => {
    //       state.responseMsg = data;
    //       state.datasent = 1;
    //       var a = document.createElement('a');
    //       a.href = thanks_url;
    //       a.target = '_top';
    //       a.click();
    //       // window.location.href = thanks_url;
    //       toastSuccess('Your form has been submitted successfully!');
    //       setOpenSendForm(false);
    //     })
    //     .catch(error => {
    //       toastError('Network Error! Please check internet connection or your setting');
    //       setOpenSendForm(false);
    //       state.datasent = -1;
    //     });
  };

  const getProducts = async () => {
    try {
      const response = await axiosInstanceAuthorized
        .get(API_URL + "product/get");
      if (!render) {
        setRender(1);
        setProducts(response.data.products);
        await getOptionals(response.data.products);
      }
    } catch (err) {
      if (err.message === "Network Error") {
        toast.error(err.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    }
  };

  const getOptionals = async (products) => {
    try {
      const response = await axiosInstanceAuthorized
        .get(API_URL + "optional/get");
      setOptionals(response.data.optionals);
      await calcPrice(products, response.data.optionals);
    } catch (err) {
      if (err.message === "Network Error") {
        toast.error(err.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    }
  };

  const AddOrder = (clientId) => {
    const lang = i18n.language;
    const data = {
      client_id: clientId,
      model_name: snap.isModel
        ? title[modelID]
        : subsystems[systemID].title,
      unit: snap.length.unitName,
      width: parseInt(snap.length.width * snap.length.unit + 0.5),
      depth: parseInt(snap.length.depth * snap.length.unit + 0.5),
      height1: parseInt(snap.length.height1 * snap.length.unit + 0.5),
      height2: parseInt(snap.length.height2 * snap.length.unit + 0.5),
      cover_color: snap.structure.color,
      glass_texture: snap.blades.textureType,
      light: snap.hasLight,
      number_of_columns: cnt,
      added_columns: JSON.stringify(snap.columns.added),
      added_column_position: JSON.stringify(snap.columns.pos),
      install: snap.install == 1
      ? "With"
      : user_install == 2
        ? "Without But Calculated"
        : "Without",
      inst: snap.install,
      ledprice: totalPrice,
      weight: totalWeight,
      data: snap,
      email: "",
      user_id: userId,
      lang : lang    
    };

    axiosInstanceAuthorized
      .post(API_URL + "order/add", data)
      .then((response) => {
        toastSuccess(t("Model added to the cart successfully!"), brandColor);
        setFlag((prevFlag) => !prevFlag);
        state.basketFlag = !snap.basketFlag;
      })
      .catch((err) => {
        toastError(err.message);
      });
  };

  const AddBasket = () => {
    const lang = i18n.language;
    var cartItems = [];
    const data1 = {
      client_id: -4,
      model_name: snap.isModel ? title[modelID] : subsystems[systemID].title,
      unit: snap.length.unitName,
      width: parseInt(snap.length.width * snap.length.unit + 0.5),
      depth: parseInt(snap.length.depth * snap.length.unit + 0.5),
      height1: parseInt(snap.length.height1 * snap.length.unit + 0.5),
      height2: parseInt(snap.length.height2 * snap.length.unit + 0.5),
      cover_color: snap.structure.color,
      glass_texture: snap.blades.textureType,
      light: snap.hasLight,
      number_of_columns: cnt,
      added_columns: JSON.stringify(snap.columns.added),
      added_column_position: JSON.stringify(snap.columns.pos),
      install: snap.install,
      inst: snap.install,
      ledprice: 0,
      weight: 0,
      data: snap,
      email: "",
      user_id: userId,
      lang: lang
    };

    axiosInstanceAuthorized
      .post(API_URL + "order/add", data1)
      .then((response) => {
        var data2 = data1;
        data2.data_id = response.data.data_id;
        if (localStorage.getItem("carts"))
          cartItems = JSON.parse(localStorage.getItem("carts"));
        cartItems.push(data2);
        localStorage.setItem("carts", JSON.stringify(cartItems));

        toastSuccess(t("Model added to the cart successfully!"), brandColor);
        setFlag((prevflag) => !prevflag);
        state.basketFlag = !snap.basketFlag;
      })
      .catch((err) => {
        toastError(err.message);
      });
  };

  const isMobile = useMediaQuery("(max-width:426px)");

  const orderNow = () => {
    // const user = JSON.parse(localStorage.getItem('user')).user;
    // AddOrder(user.employee_id);
    setOpen(true);
  };

  React.useEffect(() => {
    // setLoading(true);
    setRender(0);
    hash_list = {};
    setTP(0);
    getProducts();
    CalcCapella(
      snap.length.width,
      snap.length.depth,
      snap.systemVisible,
      modelID
    );
    render_cnt = 0;
  }, [flag, status, snap]);

  return (
    <>
      <Grid container>
        <Grid item lg={12} md={12} sm={12} xs={12} className="main">
          <Box
            sx={{
              overflow: "auto",
              background: isDark ? "black" : "white",
            }}
          >
            <Stack
              direction="row"
              justifyContent="space-between"
              sx={{ px: { md: 5, xs: 3 }, py: { md: 4, xs: 3 } }}
            >
              <ModelLog pos="home" />
              {mode === 0 || (mode === 1 && showprice === 1) ? (
                <div
                  style={{
                    position: "absolute",
                    right: "85px",
                    top: "35px",
                  }}
                >
                  {/* <Basket color="black"/> */}
                  <Cart color="black" />
                </div>
              ) : null}
              {mode === 0 ? <UserMenu /> : null}
            </Stack>
            <Box sx={{ p: { md: 5, xs: 3 }, mb: showprice == 0 ? 5 : 0 }}>
              {products.length > 0 ? (
                <Tables
                  user={user}
                  products={products}
                  modelCount={modelCount}
                  setModelCount={setModelCount}
                  optionals={optionals}
                  brandColor={brandColor}
                  setTP={setTP}
                  setAction={setAction}
                  setDisabled={setDisabled}
                />
              ) : null}
              {
                // if embed show loading
                mode === 1 && loading ? (
                  <Box
                  sx={{
                    display: { sm: "flex", xs: "flex" },
                    p: { md: 5, xs: 3 },
                    pr: { md: 0, xs: 0 },
                    alignItems: "center",
                    position: "relative",
                    float: { md: "right", xs: "left" },
                    mt: { md: "20px", xs: 0 },
                    mb: { md: "50px", xs: 0 },
                  }}
                >
                  <Typography 
                  align="left"
                  sx={{ fontSize: isMobile ? "20px" : "30px", mr: { md: 5, xs: 5 } }}
                  >{t('Loading...')}</Typography>
                </Box>
                ) : null
              }
              {mode === 0 || (mode === 1 && showprice === 1) ? (
                <Box
                  sx={{
                    display: { sm: "flex", xs: "flex" },
                    p: { md: 5, xs: 3 },
                    pr: { md: 0, xs: 0 },
                    alignItems: "center",
                    position: "relative",
                    float: { md: "right", xs: "left" },
                    mt: { md: "20px", xs: 0 },
                    mb: { md: "50px", xs: 0 },
                  }}
                >
                  {isMobile ? (
                    <Typography
                      align="left"
                      sx={{ fontSize: "20px", mr: { md: 5, xs: 5 } }}
                    >
                      {/* Total Price: {totalPrice < 0.1 ? "Calculating..." : '$' + FormatNumber(totalPrice)} */}
                      {t('Total Price')}:{" "}
                      {loading
                        ? t("Calculating...")
                        : "$" + FormatNumber(totalPrice)}
                    </Typography>
                  ) : (
                    <Typography
                      align="right"
                      sx={{ fontSize: "30px", mr: { md: 5, xs: 5 } }}
                    >
                      {t('Total Price')}:{" "}
                      {loading
                        ? t("Calculating...")
                        : "$" + FormatNumber(totalPrice)}
                    </Typography>
                  )}
                  <br />
                  {snap.isModel ? (
                    <Button
                      variant="contained"
                      disabled={totalPrice < 0.1 || disabled}
                      onClick={(e) => {
                        e.preventDefault();
                        setTP(0);
                        setLoading(true);
                        setRender(0);
                        setStatus((prevstatus) => !prevstatus);
                        setDisabled(true);
                      }}
                      sx={{
                        bgcolor:
                          brandColor === "null" || brandColor === null
                            ? "#7596AE"
                            : brandColor,
                        fontSize: 15,
                        lineHeight: 1,
                        px: totalPrice < 0.1 ? 6 : 2.5,
                        py: 1.25,
                        "&:hover": { bgcolor: Darken(brandColor) },
                      }}
                    >
                      {/* {totalPrice < 0.1 ?
                        <CircularProgress size={15} className="circular_loader" />
                        :
                        'RECALCULATE'} */}
                      {loading ? (
                        <CircularProgress
                          size={15}
                          className="circular_loader"
                        />
                      ) : (
                        t("RECALCULATE")
                      )}
                    </Button>
                  ) : null}
                </Box>
              ) : null}
            </Box>
          </Box>
          <Box
            sx={{
              display: { sm: "flex" },
              p: { md: 5, xs: 3 },
              alignItems: "center",
              justifyContent: {
                xs: "center",
                md: "end",
              },
              textAlign: "center",
              width: "100%",
              position: "relative",
              float: "right",
              mt: { md: "-100px", xs: 0 },
              mb: { md: "50px", xs: 0 },
            }}
          >
            <Button
              variant="contained"
              disabled={steps[modelID].length !== activeStep + 1 || loading}
              onClick={(e) => {
                e.preventDefault();
                setOpenSendForm(true);
              }}
              sx={{
                bgcolor:
                  brandColor === "null" || brandColor === null
                    ? "#7596AE"
                    : brandColor,
                fontSize: 15,
                lineHeight: 1,
                px: 2.5,
                py: 1.25,
                mr: 4,
                // mt: (showprice === 0? 5:''),
                "&:hover": { bgcolor: Darken(brandColor) },
              }}
            >
              {/* {mode === 0 ? "SEND TO DEALER" : "SUBMIT"} */}
              {t('GET ESTIMATE')}
            </Button>
            {mode === 0 || (mode === 1 && showprice === 1) ? (
              <Button
                variant="contained"
                disabled={steps[modelID].length !== activeStep + 1 || loading}
                onClick={(e) => {
                  e.preventDefault();
                  setRender(0);

                  if (!mode) AddOrder(-3);
                  else AddBasket();
                  state.basketFlag = !snap.basketFlag;
                }}
                sx={{
                  bgcolor:
                    brandColor === "null" || brandColor === null
                      ? "#7596AE"
                      : brandColor,
                  fontSize: 15,
                  lineHeight: 1,
                  px: 2.5,
                  py: 1.25,
                  "&:hover": { bgcolor: Darken(brandColor) },
                }}
              >
                {t('ADD TO BASKET')}
              </Button>
            ) : null}
          </Box>
        </Grid>
      </Grid>
      <OrderForm open={open} setOpen={setOpen} AddOrder={AddOrder} />
      <Dialog open={openSendForm} onClose={handleClose} fullWidth>
        <div className="d-flex align-items-center justify-content-between">
          <DialogTitle>{t('Send Configuration Data')}</DialogTitle>
          <CloseIcon
            sx={{
              mr: 3,
            }}
            onClick={handleClose}
          />
        </div>
        <DialogContent>
          <ValidationForm handleSend={handleSend} brandColor={brandColor} />
        </DialogContent>
        <DialogActions></DialogActions>
      </Dialog>
    </>
  );
};

export default OverviewTable;
