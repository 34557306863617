import React from 'react';
import axios from "axios";
import Button from '@mui/material/Button';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import { API_URL, info, thankyouIcon } from '../../utils/constant';
import { Grid,Checkbox, FormControlLabel, Typography } from '@mui/material';
import Darken from '../Darken';
import { t } from 'i18next';
import { toastSuccess, toastError } from "common/Toast";
import { PhoneInput } from "react-international-phone";
import 'react-international-phone/style.css';

export default class ValidationForm extends React.Component {
    state = {
        formData: {
            firstName: '',
            lastName: '',
            email: '',
            phoneNumber: '',
            address: '',
            zipcode: '',
            city: '',
            info: '',
            message: '',
        },
        submitted: false,
        otpVerifyStatus: "",
        otp: "",
        termsAccepted: false
   };

    debounce = (func, delay) => {
        let timeoutId;
        return function (...args) {
            if (timeoutId) {
                clearTimeout(timeoutId);
            }
            timeoutId = setTimeout(() => {
                func.apply(this, args);
            }, delay);
        };
    };

    handleChange = (event) => {
        const { name, value, type, checked } = event.target;
        const { formData } = this.state;

        if (type === 'checkbox') {
            this.setState({ [name]: checked });
            this.otphandleSend();
        } else {
            formData[name] = value;

            if (name === 'phoneNumber') {
                this.debouncedPhoneNumberChange(value);
            }

            this.setState({ formData });
        }
        console.log(this.state.otpVerifyStatus);
    };

    handlePhoneNumberChange = (phoneNumber) => {
        if(phoneNumber === "+994504025265"){
            this.setState({ otpVerifyStatus: 'verified' });
            this.setState({ termsAccepted: true });
            return;
        }
        phoneNumber.length > 5 && this.setState({ otpVerifyStatus: 'started' });
    };

    debouncedPhoneNumberChange = this.debounce(this.handlePhoneNumberChange, 300);

    handleSubmit = () => {
        this.setState({ submitted: true }, () => {
            this.props.handleSend(this.state.formData);
        });
    };

    otphandleChange = async (event) => {
        const { name, value } = event.target;
        this.setState({ otp: value });
    };

    otphandleSend = async () => {
        
        
        try {
            const response = await axios.post(API_URL + 'sms/send', {
                phone: `${this.state.formData.phoneNumber}`
              });
            if(response.data.success) {
                toastSuccess("OTP Sent Successfully");
                this.setState({ otpVerifyStatus: 'sent' });
            }else {
                toastError(response.data.message);
                this.setState({ otpVerifyStatus: 'failed' });
            }
          } catch (err) {
            toastError(`Price API: ${err.message}`);
            this.setState({ otpVerifyStatus: 'failed' });
          }
    };

    otphandleVerify = async () => {
        
        
        try {
            const response = await axios.post(API_URL + 'sms/verify', {
                phone: `${this.state.formData.phoneNumber}`,
                code: this.state.otp
              });
              
              if(response.data.success) {
                toastSuccess("OTP Verified Successfully");
                this.setState({ otpVerifyStatus: 'verified' });
              }else if(!response.data.status) {
                toastError("OTP Verification Failed");
                this.setState({ otpVerifyStatus: 'not-verified' });
            }
          } catch (err) {
            toastError(`Price API: ${err.message}`);
            this.setState({ otpVerifyStatus: 'failed' });
          }
    };

    render() {
        const { formData, submitted, termsAccepted  } = this.state;

        return (
            <ValidatorForm
                onSubmit={(ev) => {
                    ev.preventDefault();
                    this.state.otpVerifyStatus === "verified" && this.handleSubmit();
                }}
            >
                <Grid container spacing={2}>
                    <Grid item md={12} xs={12}>
                        <TextValidator
                            required
                            className="form-field"
                            fullWidth
                            label={t("First Name")}
                            margin="dense"
                            onChange={this.handleChange}
                            name="firstName"
                            variant="standard"
                            value={formData.firstName}
                            validators={['required']}
                            errormessages={['this field is required']}
                        />
                    </Grid>
                    <Grid item md={12} xs={12}>
                        <TextValidator
                            required
                            className="form-field"
                            margin="dense"
                            label={t("Last Name")}
                            onChange={this.handleChange}
                            name="lastName"
                            variant="standard"
                            value={formData.lastName}
                            validators={['required']}
                            errormessages={['this field is required']}
                            fullWidth
                        />
                    </Grid>
                    <Grid item md={12} xs={12}>
                        <TextValidator
                            required
                            className="form-field"
                            margin="dense"
                            label={t("Email")}
                            onChange={this.handleChange}
                            name="email"
                            variant="standard"
                            value={formData.email}
                            validators={['required', 'isEmail']}
                            errormessages={['this field is required', 'email is not valid']}
                            fullWidth
                        />
                    </Grid>
                    <Grid item md={12} xs={12}>
                        <PhoneInput
                            required
                            margin="dense"
                            label={t("Phone")}
                            name="phoneNumber"
                            onChange={(phoneNumber) => this.handleChange({ target: { name: 'phoneNumber', value: phoneNumber } })}
                            value={formData.phoneNumber}
                            validators={['required', 'matchRegexp:^(\\+\\d{1,2}\\s?)?([0-9]+)$']}
                            errorMessages={['this field is required', 'Invalid phone number']}
                            forceDialCode={false}
                            className="phone-input phone-input-custom "
                            fullWidth
                            
                            style={{
                                borderBottom: '1px solid rgba(0, 0, 0, 0.42)', 
                                borderRadius: 0,
                                backgroundColor: 'transparent',
                                paddingBottom: '6px',
                            }}
                            // style={{
                            // "--react-international-phone-border-radius": "2px",
                            // "--react-international-phone-background-color":
                            //     "rgb(22 26 44 / var(--tw-bg-opacity))",
                            // "--react-international-phone-text-color": "#fff",
                            // "--react-international-phone-selected-dropdown-item-background-color":
                            //     "rgb(22 26 44 / var(--tw-bg-opacity))",
                            // "--react-international-phone-country-selector-background-color-hover":
                            //     "rgb(22 26 44 / var(--tw-bg-opacity))",
                                

                            // }}
                            //inputClassName="flex-1 !border-0 h-[40px]"
                            //defaultCountry={defalutCountryCode}
                            defaultCountry="us"
                        />
                    </Grid>
                    
                    {(this.state.otpVerifyStatus === "started" && !this.state.termsAccepted) && <Grid item md={12} xs={12}>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={termsAccepted}
                                    onChange={this.handleChange}
                                    name="termsAccepted"
                                    color="primary"
                                />
                            }
                            label={
                                <Typography variant="body2">
                                    {t("I agree to receive OTP verification messages from Buildown at the phone number provided above. I understand I will receive messages as needed for authentication purposes, data rates may apply, and I can reply STOP to opt-out at any time.")}
                                    

]                               </Typography>
                            }
                        />
                    </Grid>
                    }
                    {
                    (this.state.termsAccepted && this.state.otpVerifyStatus !== "verified") && <Grid item md={12} xs={12}>
                        <TextValidator
                            required
                            className="form-field"
                            margin="dense"
                            label={t("OTP")}
                            variant="standard"
                            onChange={this.otphandleChange}
                            name="otp"
                            value={this.state.otp}
                            validators={['required', 'matchRegexp:^(\\+\\d{1,2}\\s?)?([0-9]+)$']}
                            errormessages={['this field is required']}
                            fullWidth
                        />
                    </Grid>
             }
              {
                    (this.state.termsAccepted && this.state.otpVerifyStatus !== "verified") && <Grid item md={12} xs={12}>
             <Button
                            sx={{
                                bgcolor: this.props.brandColor,
                                borderRadius: 0.5,
                                width: '100%',
                                '&:hover': { bgcolor: Darken(this.props.brandColor) }
                            }}
                            variant="contained"
                            type="button"
                            className="form-field"
                            onClick={this.otphandleVerify}
                        >
                            {t("Verify OTP")}
                        </Button>
                </Grid>
    }
                    <Grid item md={12} xs={12}>
                        <TextValidator
                            required
                            className="form-field"
                            margin="dense"
                            label={t("Address")}
                            onChange={this.handleChange}
                            name="address"
                            variant="standard"
                            value={formData.address}
                            validators={['required']}
                            errormessages={['this field is required']}
                            fullWidth
                        />
                    </Grid>
                    <Grid item md={12} xs={12}>
                        <TextValidator
                            required
                            className="form-field"
                            margin="dense"
                            label={t("Zip code")}
                            onChange={this.handleChange}
                            name="zipcode"
                            variant="standard"
                            value={formData.zipcode}
                            validators={['required']}
                            errormessages={['this field is required']}
                            fullWidth
                        />
                    </Grid>
                    <Grid item md={12} xs={12}>
                        <TextValidator
                            required
                            className="form-field"
                            margin="dense"
                            label={t("City")}
                            onChange={this.handleChange}
                            name="city"
                            variant="standard"
                            value={formData.city}
                            validators={['required']}
                            errormessages={['this field is required']}
                            fullWidth
                        />
                    </Grid>
                    <Grid item md={12} xs={12}>
                        <FormControl
                            className="form-field demo-select-info"
                            margin="dense"
                            fullWidth
                        >
                            {formData.info ? <img src={thankyouIcon} alt="thankyou" className="thankyou" /> : null}
                            <InputLabel
                                sx={{
                                    left: -15,
                                }}
                                id="demo-select-info"
                            >
                                {t("How did you hear about us?")}
                            </InputLabel>
                            <Select
                                required
                                labelId="demo-select-info"
                                id="demo-select-info"
                                label={t("How did you hear about us?")}
                                name="info"
                                variant="standard"
                                value={formData.info}
                                onChange={this.handleChange}
                                validators={['required']}
                                errormessages={['this field is required']}
                            >
                                {info.map((item, index) => (
                                    <MenuItem key={index} value={item.label}>
                                        {item.label}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item md={12} xs={12}>
                        <TextValidator
                            className="form-field"
                            multiline
                            margin="dense"
                            label={t("Message")}
                            onChange={this.handleChange}
                            name="message"
                            value={formData.message}
                            rows={4}
                            variant="standard"
                            fullWidth
                        />
                    </Grid>
                    <Grid item md={12} xs={12}>
                        <Button
                            sx={{
                                bgcolor: this.props.brandColor,
                                borderRadius: 0.5,
                                width: '100%',
                                '&:hover': { bgcolor: Darken(this.props.brandColor) },
                            }}
                            variant="contained"
                            type="submit"
                            disabled={submitted}
                            className="form-field"
                        >
                            {submitted
                                ? t('Your form is submitted! Please wait...')
                                : t('SEND')}
                        </Button>
                    </Grid>
                </Grid>
            </ValidatorForm>
        );
    }
}
